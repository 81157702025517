<template>
  <div ref="detailedList" class="detailed-list" @scroll="scrollElement">
    <div ref="header" class="header">
      <div class="back" @click="goBack">
        <div class="back-icon"></div>
      </div>
      <div class="title">直播红包明细</div>
    </div>
    <div ref="content" class="content">
      <div class="get-baglist">
        <div v-for="(item, index) in list" :key="index" class="bag-item">
          <div class="item-box">
            <div class="get-info">
              <div class="bag-info">
                <div class="bag-type">领取直播红包</div>
                <div class="bag-status">{{ item.status_text }}</div>
              </div>
              <div class="bag-time">{{ item.receive_time }}</div>
            </div>
            <div class="amount-info">{{ item.red_package_amount }}元</div>
          </div>
          <div class="item-border"></div>
        </div>
      </div>
      <!-- <div v-if="isLoaded" class="bottom-tips">我也是有底线的 ~</div> -->
    </div>
    <Loading v-if="isLoading" class="loading-box" type="spinner" />
  </div>
</template>

<script>
import { getUserRecordList } from '@/api/redBag';
import { Loading } from 'vant';
import { getDeviceType } from '@/utils';
export default {
  name: 'GetDetailed',
  components: {
    Loading,
  },
  data() {
    return {
      app_id: window.sessionStorage.getItem('appid'),
      token: window.sessionStorage.getItem('ato'),
      list: [],
      last_id: 0,
      isLoading: false, //是否显示loading
      isLoaded: false, //是否继续加载
    };
  },
  activated() {
    //重置列表参数
    this.resetListParams();
    this.getList();
  },
  methods: {
    //重置列表参数
    resetListParams() {
      this.list.splice(0, this.list.length);
      this.isLoading = false;
      this.isLoaded = false;
      this.last_id = 0;
    },
    scrollElement(e) {
      const scrollTop = e.target.scrollTop; //滚动距离
      const contaienrHeight = this.$refs['detailedList'].clientHeight; //视口高度
      const listHeight = this.$refs['header'].clientHeight + this.$refs['content'].clientHeight;
      if (scrollTop > listHeight - contaienrHeight - 5) {
        console.log('到底部了', this.isLoading);
        if (this.isLoading) return;
        if (this.isLoaded) return; //没有数据了
        this.last_id = this.list[this.list.length - 1].id;
        this.getList();
      }
    },
    getList() {
      this.isLoading = true;
      getUserRecordList({
        app_id: this.app_id,
        token: this.token,
        last_id: this.last_id,
        os: getDeviceType(),
        channel_id: window.sessionStorage.getItem('channel_id'),
        pf: window.sessionStorage.getItem('pf'),
      })
        .then((res) => {
          console.log('红包明细列表', res);
          if (res.data.status === 200) {
            this.list.push(...res.data.data);
            if (res.data.data.length < 15) {
              this.isLoaded = true;
            }
          } else {
            this.$toast(res.data.msg);
          }
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.detailed-list {
  background-color: #f7f7f7;
  padding: 0 0.28rem;
  height: 100vh;
  overflow-y: auto;
  .header {
    position: relative;

    .back {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 1rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 0.36rem;
      .back-icon {
        width: 0.2rem;
        height: 0.29rem;
        background-image: url('../../assets/imgs/back.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
    .title {
      text-align: center;
      font-size: 0.34rem;
      font-weight: 500;
      color: #222222;
      line-height: 1.35rem;
    }
  }
  .content {
    padding: 0 0.36rem 0.2rem;
    background: #fefefe;
    box-shadow: 0px 0.02rem 0.01rem 0px rgba(200, 200, 200, 0.1);
    border-radius: 0.05rem;

    .get-baglist {
      min-height: calc(100vh - 1.35rem);
      .bag-item {
        .item-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0.3rem 0 0.26rem;
          .get-info {
            display: flex;
            // align-items: center;
            flex-direction: column;
            justify-content: space-between;
            height: 0.76rem;
            .bag-info {
              display: flex;
              align-items: center;

              .bag-type {
                font-size: 0.28rem;
                font-weight: 400;
                color: #222222;
                line-height: 0.32rem;
                margin-right: 0.38rem;
              }
              .bag-status {
                font-size: 0.26rem;
                font-weight: 400;
                color: #8d8d8d;
                line-height: 0.32rem;
              }
            }
            .bag-time {
              font-size: 0.26rem;
              font-weight: 400;
              color: #8d8d8d;
              line-height: 0.32rem;
            }
          }
          .amount-info {
            font-size: 0.28rem;
            font-weight: 500;
            color: #e99732;
            line-height: 0.32rem;
          }
        }
        .item-border {
          height: 0.02rem;
          background-image: url('../../assets/imgs/split-border.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
    }
    .bottom-tips {
      text-align: center;
      font-size: 0.28rem;
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      border-top: 1px solid rgba(0, 0, 0, 0.06);
    }
  }
}
.loading-box {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
</style>
